import React from "react"
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Nav from "react-bootstrap/Nav"
import Accordion from "react-bootstrap/Accordion"
import ListGroup from "react-bootstrap/ListGroup"
import Button from "react-bootstrap/Button"

const SidebarMenu = (props) => {
    const data = useStaticQuery(
        graphql
            `query {
                allSidebarNavigationJson {
                    edges {
                      node {
                        sidebarNavigation {
                          name
                          path
                          label
                          subitems{
                            name
                            path
                            label
                            type
                          }
                        }
                      }
                    }
                  }
            }
            `
    )
    
    const location = useLocation()
    const level_one = location.pathname.match("([^/]+$)")
    const level_two = location.pathname.match(".*/(.*?)/")
    let activeKey

    if(level_two !== null){
        activeKey = level_two[1]
    } else if(level_one !== null){
        activeKey = level_one[1]
    }

    let menuData = data.allSidebarNavigationJson.edges[0].node.sidebarNavigation
    
    return (
        <Nav className="flex-column"> 
            <Accordion defaultActiveKey={activeKey}>
            {menuData.map(function(menuEntry){
                return(
                    <div className="navWrap">
                        <Accordion.Toggle as={Button} variant="link" eventKey={menuEntry.name}>
                            <Nav.Item>
                                <Link className="mainEntry" to={menuEntry.path}>{menuEntry.label}</Link>
                            </Nav.Item>
                        </Accordion.Toggle>
                        {menuEntry.subitems ?
                        <Accordion.Collapse className="subEntries" eventKey={menuEntry.name}>
                            <ListGroup>                    
                                {menuEntry.subitems.map((subitem) =>
                                    <ListGroup.Item key={subitem.name}>
                                        {subitem.type == null ? <Link to={subitem.path}>{subitem.label}</Link> : null}
                                        {subitem.type === 'normal' ? <Link to={subitem.path}>{subitem.label}</Link> : null}
                                        {subitem.type === 'parent' ? <Link className="hash-parent" to={subitem.path}>{subitem.label}</Link> : null}
                                        {subitem.type === 'hashlink' ? <AnchorLink className="hash-link" to={subitem.path}>{subitem.label}</AnchorLink> : null}
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Accordion.Collapse>
                        : null}
                    </div>
                )
            })}
            </Accordion>
        </Nav>
    )
}

export default SidebarMenu