import React from "react"
import SidebarMenu from "./sidebarMenu"

import mdkLogo from "../images/mdk_logo.png"
import mdkReport from "../images/transparenzbericht_2023.pdf"

const Sidebar = (props) => (
        <div className={props.sidebarClass}>
          <SidebarMenu menuPosition="sidebar" />
          <div className="report-box">
            <a href={mdkReport} target="_blank" rel="noreferrer"><img src={mdkLogo} alt="Transparenzbericht 2023" /></a>
          </div>
        </div>
        )

export default Sidebar