import React from 'react'

const SidebarToggle = (props) => (
    <div className="sidebarToggle" onClick={props.click} buttonState={props.buttonState}>
        <div className="sidebarButton">
            {props.buttonState ? 
            <svg width="30px" height="30px" viewBox="0 0 16 16" class="bi bi-list" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg> : 
            <svg width="30px" height="30px" viewBox="0 0 16 16" class="bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
            }
        </div>
    </div>
)

export default SidebarToggle